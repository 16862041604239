import { formatDateTime } from "@bussola-viagens/utils"
import { GridActionsCellItem, GridDeleteIcon, GridRowParams } from "@mui/x-data-grid"
import { useFormik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Button, FileUploader, Grid, Input, Layout, Loader, LoaderContainer, Modal, Paper, Table } from "../../../components"
import { useAPI, useToggle } from "../../../hooks"
import DownloadIcon from '@mui/icons-material/Download';
import { CreateLessonSchema, UpdateTrainingSchema } from "@bussola-viagens/validations"

export const TrainingPage = () => {
    const { id } = useParams()
    const [modal, modalControls] = useToggle(false)
    const [training, refetchTraining] = useAPI(`/training/${id}`)

    const [deletedLesson, deleteLesson] = useAPI({
        method: 'DELETE'
    }, {
        manual: true
    })

    const [dowloadedFile, downloadFile] = useAPI({
        method: 'GET',
    }, {
        manual: true
    })

    const [updatedTraining, updateTraining] = useAPI({}, {
        manual: true
    })
    const [createdLesson, createLesson] = useAPI({
        url: `/training/${id}/lesson`,
        method: 'POST'
    }, {
        manual: true
    })

    const form = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema: UpdateTrainingSchema,
        onSubmit: async (data) => {
            const { status } = await updateTraining({
                url: `/training/${id}`,
                method: 'PUT',
                data
            })

            if (status <= 299) {
                enqueueSnackbar('Treinamento atualizado com sucesso!', {variant: 'success'})
                refetchTraining()
            }
        },
        initialStatus: 'draft'
    })

    const lessonForm = useFormik({
        initialValues: {
            name: '',
            file: null
        },
        validationSchema: CreateLessonSchema,
        onSubmit: async (value, helpers) => {
            const formData = new FormData();
            
            formData.append('name', value.name)
            formData.append('file', (value as unknown as { file: File }).file)
            
            const { status } = await createLesson({
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            if (status <= 299) {
                enqueueSnackbar('Aula adicionada com sucesso!', { variant: 'success' })
                refetchTraining()
                helpers.resetForm()
                modalControls.setFalse()
            }
        }
    })

    useEffect(() => {
        if (training.data && form.status === 'draft') {
            form.resetForm({
                values: {
                    name: training.data.name
                },
                status: 'initiated'
            })
        }
    }, [form, training.data])

    if (training.loading || form.status === 'draft' || deletedLesson.loading || dowloadedFile.loading || updatedTraining.loading || createdLesson.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container p={2} spacing={1}>
                <Grid item xs={6}>
                    <Paper>
                        <form onSubmit={form.handleSubmit}>
                            <Grid container spacing={1} p={2}>
                                <Grid item xs={12}>
                                    <Input
                                        name="name"
                                        label="Nome"
                                        value={form.values.name}
                                        onChange={form.handleChange}
                                        error={!!form.touched.name && !!form.errors.name}
                                        helperText={form.touched.name && form.errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    <Button type="submit">
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid> 
                        </form>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} textAlign="right">
                            <Button onClick={modalControls.setTrue}>
                                Adicionar
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Table 
                                    columns={[
                                        { field: 'created_at', flex: 1, type: 'dateTime', headerName: 'Data de Criação', valueGetter: (value) => new Date(value), valueFormatter: formatDateTime },
                                        { field: 'name', flex: 1, type: 'string', headerName: 'Nome' },
                                        { field: 'actions', type: 'actions', getActions: (params: GridRowParams) => [
                                            <GridActionsCellItem 
                                                icon={<DownloadIcon />} 
                                                onClick={() => {
                                                    downloadFile({
                                                        url: `/file/${params.row.file_id}`,
                                                        responseType: 'blob'
                                                    }).then((res) => {
                                                        if (res.status === 200) {
                                                            const blobUrl = URL.createObjectURL(res.data);
            
                                                            window.open(blobUrl, '_blank')
                                                        }
                                                    })
                                                }} 
                                                label="Fazer Download" 
                                            />,
                                            <GridActionsCellItem 
                                                icon={<GridDeleteIcon />} 
                                                onClick={async () => {
                                                    const {status} = await deleteLesson({
                                                        url: `/training/${id}/lesson/${params.id}`,
                                                        method: 'DELETE'
                                                    })
            
                                                    if (status <= 299) {
                                                        enqueueSnackbar('Aula excluída com sucesso!', {variant: 'success'})
                                                        refetchTraining()
                                                    }
                                                }} 
                                                label="Fazer Download" 
                                            />,
                                        ] }
                                    ]}
                                    rows={training.data.lessons}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {modal && (<Modal
                open={modal}
                onClose={modalControls.setFalse}
                title="Nova Aula"
                onConfirm={lessonForm.handleSubmit}
                confirmLabel="Adicionar"
                bodyCustomCss={{
                    width: 500
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Input
                            name="name"
                            label="Nome"
                            value={lessonForm.values.name}
                            onChange={lessonForm.handleChange}
                            error={!!lessonForm.touched.name && !!lessonForm.errors.name}
                            helperText={lessonForm.touched.name && lessonForm.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FileUploader
                            label="Vídeo"
                            onChange={(value) => {
                                lessonForm.setFieldValue('file', value, true)
                            }}
                            value={lessonForm.values.file}
                            inputProps={{
                                accept: 'video/*'
                            }}
                            helperText={lessonForm.touched.file && lessonForm.errors.file}
                            error={!!lessonForm.touched.file && !!lessonForm.errors.file}
                        />
                    </Grid>
                </Grid>
            </Modal>)}
        </Layout>
    )
}