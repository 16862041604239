import { PROGRAM, PROGRAMS } from "@bussola-viagens/constants"
import { TabPanel, useTabs, useTabsList } from "@mui/base"
import { Alert, AlertTitle, Box, Tab, Tabs, useTheme } from "@mui/material"
import { useFormik } from "formik"
import { useContext, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Grid, Input, Layout, Paper } from "../../components"
import { AuthContext } from "../../contexts/AuthContext"
import { AAdvantageCalculator } from "./AAdvantageCalculator"
import { AeroplanCalculator } from "./AeroplanCalculator"
import { ARCompanyCalculator } from "./ARCompanyCalculator"
import { AzulViagensCalculator } from "./AzulViagensCalculator"
import { ConnectMilesCalculator } from "./ConnectMilesCalculator"
import { DefaultCalculator } from "./DefaultCalculator"
import { FlyingBlueCalculator } from "./FlyingBlueCalculator"
import { IberiaPlusCalculator } from "./IberiaPlusCalculator"
import { LifemilesCalculator } from "./LifemilesCalculator"

const Calculators = {
    [PROGRAM.TAP]: DefaultCalculator,
    [PROGRAM.AADVANTAGE]: AAdvantageCalculator,
    [PROGRAM.AZUL_INTERLINE]: DefaultCalculator,
    [PROGRAM.AZUL_VIAGENS]: AzulViagensCalculator,
    [PROGRAM.IBERIA_PLUS]: IberiaPlusCalculator,
    [PROGRAM.LATAMPASS_AR]: ARCompanyCalculator,
    [PROGRAM.LATAMPASS_BR]: DefaultCalculator,
    [PROGRAM.SMILES_AR]: ARCompanyCalculator,
    [PROGRAM.SMILES_BR]: DefaultCalculator,
    [PROGRAM.TUDO_AZUL]: DefaultCalculator,
    [PROGRAM.AIR_EUROPA]: DefaultCalculator,
    [PROGRAM.FLYING_BLUE]: FlyingBlueCalculator,
    [PROGRAM.LIFEMILES]: LifemilesCalculator,
    [PROGRAM.CONNECT_MILES]: ConnectMilesCalculator,
    [PROGRAM.AEROPLAN]: AeroplanCalculator,
    [PROGRAM.TUDO_AZUL_V2]: DefaultCalculator
}

export const HomePage = () => {
    const { me } = useContext(AuthContext)

    const groupPrograms = useMemo(() => {
        return Object.keys(me?.group?.configuration as { [key: string]: any } || {}).sort((a, b) => a.localeCompare(b)) as PROGRAM[]
    }, [me?.group?.configuration])

    const tabs = useTabs({
        defaultValue: groupPrograms?.[0],
    })

    if (!me?.group) {
        return (
            <Layout>
                <Grid container p={2} spacing={1} justifyContent={'center'}>
                    <Grid item xs={12}>
                        <Alert
                            icon={false}
                            color="warning"
                        >
                            <AlertTitle>Atenção!</AlertTitle>
                            Você ainda não faz parte de nenhum grupo.<br/>Entre em contato com o administrador do sistema.
                        </Alert>
                    </Grid>
                </Grid>
            </Layout>
        )
    }

    if (!groupPrograms.length) {
        return (
            <Layout>
                <Grid container p={2} spacing={1} justifyContent={'center'}>
                    <Grid item xs={12}>
                        <Alert
                            icon={false}
                            color="warning"
                        >
                            <AlertTitle>Atenção!</AlertTitle>
                            O grupo que você faz parte não possui permissões de nenhum programa de fidelidade.<br/>Entre em contato com o administrador do sistema.
                        </Alert>
                    </Grid>
                </Grid>
            </Layout>
        )
    }

    return (
        <Layout>
            <Grid container p={2} spacing={1} justifyContent={'center'}>
                <Grid item xs={12}>
                    <Paper>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabs.contextValue.value} onChange={tabs.contextValue.onSelected}>
                                {
                                    groupPrograms.map(program => {
                                        return (
                                            <Tab label={PROGRAMS.find(p => p.value === program)?.label} value={program} />
                                        )
                                    })
                                }
                            </Tabs>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    {
                        groupPrograms.map(program => {
                            const Component = Calculators[program]

                            // @ts-ignore
                            const configuration = me?.group.configuration?.[program]
                            
                            return (
                                <Paper style={{
                                    padding: 15,
                                    display: program === tabs.contextValue.value ? '' : 'none'
                                }}>
                                    <Component configuration={configuration} />
                                </Paper>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Layout>
    )
}