import { formatDateTime } from "@bussola-viagens/utils"
import { GridActionsCellItem, GridDeleteIcon, GridRowParams } from "@mui/x-data-grid"
import { useFormik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useContext, useEffect } from "react"
import { Navigate, useParams } from "react-router-dom"
import { Button, FileUploader, Grid, Input, Layout, Loader, LoaderContainer, Modal, Paper, Table } from "../../../components"
import { useAPI, useToggle } from "../../../hooks"
import DownloadIcon from '@mui/icons-material/Download';
import { CreateLessonSchema, UpdateTrainingSchema } from "@bussola-viagens/validations"
import { AuthContext } from "../../../contexts"

export const MyTrainingPage = () => {
    const { id } = useParams()
    const [training] = useAPI(`/training/${id}`)

    const [dowloadedFile, downloadFile] = useAPI({
        method: 'GET',
    }, {
        manual: true
    })

    const { me } = useContext(AuthContext)

    if (!me?.group.group_module?.is_training_enabled) {
        return (
            <Navigate to="/home" />
        )
    }

    if (training.loading || dowloadedFile.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container p={1} spacing={1} justifyContent="center">
                <Grid item xs={10}>
                    <Paper>
                        <Table 
                            columns={[
                                { field: 'created_at', flex: 1, type: 'dateTime', headerName: 'Data de Criação', valueGetter: (value) => new Date(value), valueFormatter: formatDateTime },
                                { field: 'name', flex: 1, type: 'string', headerName: 'Nome' },
                                { field: 'actions', type: 'actions', getActions: (params: GridRowParams) => [
                                    <GridActionsCellItem 
                                        icon={<DownloadIcon />} 
                                        onClick={() => {
                                            downloadFile({
                                                url: `/file/${params.row.file_id}`,
                                                responseType: 'blob'
                                            }).then((res) => {
                                                if (res.status === 200) {
                                                    const blobUrl = URL.createObjectURL(res.data);

                                                    window.open(blobUrl, '_blank')
                                                }
                                            })
                                        }} 
                                        label="Fazer Download" 
                                    />
                                ] }
                            ]}
                            rows={training.data.lessons}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}